import './scroll';

window.addEventListener('load', function () {
    var video = document.querySelector('#bgvid');
    function checkLoad() {
        if (video.readyState === 4) {
            video.className = video.className + " show";
        } else {
            setTimeout(checkLoad, 100);
        }
    }
    checkLoad();

    const mobileMenuItems = document.getElementsByClassName('menu__item');
    // const mobileMenu = document.querySelector('.menu__box');
    const menuToggle = document.getElementById('menu__toggle');
    
    for (var i = 0; i < mobileMenuItems.length; i++) {
        mobileMenuItems[i].addEventListener('click', () => {
            // console.log('mobileMenu: ', mobileMenu);
            // mobileMenu.style.visibility = 'hidden';
            // mobileMenu.style.left = '-100%';
            menuToggle.checked = false;
        }, false);
    }
}, false);

window.playPause = function() {
    // Get the video
    const video = document.getElementById("bgvid");
    const fallback = document.getElementsByClassName("video_fallback")[0];

    // Get the button
    const btnPlay = document.getElementById("big-play-button");
    const btnPause = document.getElementById("pause-button");

    if (video.paused) {
        hide(fallback);
        hide(btnPlay);
        // show(btnPause);
        show(video);
        video.play();
    } else {
        video.pause();
        // show(fallback);
        show(btnPlay);
        // hide(btnPause);
    }

    function show(el) {
        el.style.display = 'block';
    }

    function hide(el) {
        el.style.display = 'none';
    }
    
}
